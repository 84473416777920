import React from 'react'
import "../styles/usb.scss"
import Seo from "../components/SEO/SEO"
import Layout from '../components/layout'
import { Link } from 'gatsby'
import HeaderPaginas from '../components/headerPaginas'




export default function paginaUsb(props) {
    return (
        <Layout>
            <HeaderPaginas titulo="" imagen="/img/cabeceras/usb-malware.jpg" />
            <Seo title="USB" pathname={props.location.pathname} robots="noindex,follow" />
            <div className="cuerpo-usb container">
                <div class="parrafo-usb">
                    <p>Estimado/a usuario/a:</p>
                    <p>Este mensaje es parte del programa de concienciación que está llevando a cabo la organización junto con <Link to="/">Bullhost Cloud Services</Link> para minimizar el riesgo de los usuarios/as frente a posibles ataques cibernéticos.</p>
                    <p>Has conectado un dispositivo fraudulento, afortunadamente no ha pasado nada. Pero, ¡cuidado! deberías saber que los dispositivos no conocidos tienes que entregarlos al responsable informatico.</p>
                    <p>Por favor, para poder seguir con este estudio y no ver trastocados los resultados, te recomendamos que no comentes con nadie todo lo relacionado con este mensaje.</p>
                    <p>Te agradecemos mucho tu colaboración. Un cordial saludo.</p>
                    <p>Correo: <a href="mailto:ciberseguridad@bullhost.es">ciberseguridad@bullhost.es</a></p>
                </div>
            </div>
        </Layout>
    )
}
