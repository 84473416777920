import React from 'react'
// import '../styles/headerPaginas.scss'


export default function HeaderPaginas(props) {


    return (
        <>
            <div className="cabecera" style={{ backgroundImage: `url(${props.imagen})` }}>
                <h1>{props.titulo}</h1>
            </div>
        </>
    );
}